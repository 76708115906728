@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(./fonts/Montserrat-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-Regular.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-Bold.ttf) format('truetype');
  font-weight: 700;
}

:root {
  --background-blue: #000c25;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background-color: #000c25;
  overflow-x: hidden;
  /* opacity: 1;
  background-image:  repeating-radial-gradient( circle at 0 0, transparent 0, #001137 11px ), repeating-linear-gradient( #002b8755, #002b87 ); */
}

.animate-delay {
  animation-delay: 0.5s;
}

.animate-delay-2 {
  animation-delay: 1s;
}

.animate-delay-3 {
  animation-delay: 1.5s;
}

a {
  color: #fff;
  text-decoration: none;
}

p {
  font-family: 'Poppins';
  font-size: 16px;
  max-width: 400px;
  text-align: center;
}

.header-ctn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
}

.header {
  display: flex;
  align-items: center;
  position: relative;
}

h1 {
  font-size: 50px;
  margin: 0;
  position: relative;
  z-index: 99;
}

h3 {
  font-size: 18px;
}

.blip {
  height: 50px;
  position: absolute;
  left: 220px;
  top: 25px;
}

.header h5 {
  position: absolute;
  left: 45px;
  top: 30px;
  font-family: 'Poppins';
  display: flex;
  align-items: center;
}

.rocket {
  height: 20px;
  margin-left: 6px;
}

.price-info-ctn {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 20px;
}

.number-block-ctn {
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  flex-direction: column;
}

.number-block-ctn h3 {
  margin-bottom: 15px;
}

.number-block {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px 30px;
  text-align: center;
  line-height: 1.3em;
  font-size: 18px;
  clip-path: polygon(100% 0, 100% 100%, 7% 100%, 0 89%, 0 0);
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
  min-width: 280px;
}

.per-person-ctn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 0px;
}

.button {
  font-size: 16px;
  border: 1px solid #fff;
  padding: 10px 40px;
  transition: all ease-in-out 200ms;
}

.button:hover {
  border: 1px solid #0074c2;
  background-color: #0074c2;
}

.per-person-ctn p img {
  height: 18px;
  margin-left: 3px;
}

.priceblock-icon {
  height: 44px;
  margin-bottom: -14px;
  z-index: 999;
  position: relative;
  /* display: none; */
}

.price-block span {
  font-weight: 700;
}

.priceblock-percent {
  font-size: 14px;
}

.priceblock-percent span {
  color: rgb(16, 185, 129);
}

.bar-ctn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  flex-direction: column;
}

.bar-ctn h3 {
  margin-bottom: 30px;
}

.bar {
  width: 90%;
  max-width: 600px;
  height: 36px;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding: 0;
  position: relative;
  border: 2px solid #00abe7;
  box-shadow: inset 0 0 14px 4px rgb(0 171 231 / 64%);
}

.inner-bar {
  height: 34px;
  background-color: #0074c2;
  background-image: linear-gradient(151deg, #273498, #0074c2 53%, #00abe7);
  /* background-image: linear-gradient(151deg, #CD0BC3, #FF21D0 53%, #FF21D0); */
  border-radius: 100px;
  position: relative;
  border: 1px solid #000;
  -webkit-transition: all 3s ease-in-out;
  -moz-transition: all 3s ease-in-out;
  -o-transition: all 3s ease-in-out;
  transition: all 3s ease-in-out;
  overflow: hidden;
}

.percentage-label {
  position: absolute;
  font-size: 11px;
  font-weight: 700;
  top: 8px;
  right: 32px;
  white-space: nowrap;
}

.ecash-icon {
  height: 24px;
  padding: 5px;
  position: absolute;
  right: 0;
}

.flip-indicator {
  width: 1px;
  height: 36px;
  background-color: #fff;
  position: absolute;
}

.fi-0 {
  left: 0%;
  width: 1px;
  height: 36px;
  position: absolute;
}

.fi-1 {
  left: 20%;
}

.fi-2 {
  left: 40%;
}

.fi-3 {
  left: 60%;
}

.fi-4 {
  left: 80%;
}

.fi-5 {
  left: 100%;
  width: 1px;
  height: 36px;
  position: absolute;
}

.fi-1:after {
  content: '20%';
  font-size: 10px;
  position: absolute;
  top: -20px;
  left: -9px;
}
.fi-2:after {
  content: '40%';
  font-size: 10px;
  position: absolute;
  top: -20px;
  left: -9px;
}
.fi-3:after {
  content: '60%';
  font-size: 10px;
  position: absolute;
  top: -20px;
  left: -9px;
}
.fi-4:after {
  content: '80%';
  font-size: 10px;
  position: absolute;
  top: -20px;
  left: -9px;
}
.fi-5:after {
  content: '100%';
  font-size: 10px;
  position: absolute;
  top: -20px;
  left: -9px;
}

.fi-0:after {
  content: '0%';
  font-size: 10px;
  position: absolute;
  top: -20px;
  left: -9px;
}

.bar-info-box {
  font-size: 14px;
  margin-top: 20px;
}

.bar-info-box span {
  font-weight: 700;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fff;
  margin-right: 2px;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
  margin-right: 2px;
}

.glow-on-hover {
  /* width: 220px;
  height: 50px; */
  /* line-height: 50px; */
  border: none;
  outline: none;
  color: #fff;
  /* background: #111; */
  cursor: pointer;
  position: relative;
  z-index: 0;
  font-size: 16px;
  padding: 20px 40px;
  font-family: 'Poppins';
  user-select: none;
  border-radius: 2px;
  /* box-shadow: 0px 4px rgba(255, 255, 255, .7); */
  transition: all 200ms ease-in-out;
}

.glow-on-hover:hover {
  /* box-shadow: 0px 8px rgba(255, 255, 255, .7); */
  transform: translateY(-2px);
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #ff21d0, #0074c2, #ff21d0);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  /* filter: blur(5px); */
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  /* opacity: 0; */
  transition: opacity 0.3s ease-in-out;
  border-radius: 3px;
}

.glow-on-hover:hover:before {
  content: '';
  background: linear-gradient(45deg, #ff21d0, #ff21d0);
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.error {
  width: 100%;
  padding: 100px 0 300px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/************************************************ CHART *****************************************************************/

.chart-ctn {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 0 10px;
  margin-top: 50px;
  flex-direction: column;
  font-size: 14px;
  margin-bottom: 100px;
}

.row-logo {
  height: 60px;
}

table {
  border-collapse: collapse;
  border: none;
  max-width: 600px;
  font-size: 14px;
}

td {
  border: 1px solid #000c25;
}

td:first-child {
  width: 40%;
  text-align: right;
}

.topRow td {
  border: none;
}

.color-cell td {
  background: rgba(255, 255, 255, 0.1);
}

table .ecash-cell {
  /* background-color: #00abe7; */
  background-color: #0074c2;
  /* box-shadow: 0px 0px 15px 2px rgba(23,243,255,0.69); */
  /* background-image: linear-gradient(151deg, #273498, #0074c2 53%, #00abe7); */
}

table .ecash-cell-border {
  /* border: 1px solid #0074C2; */
  box-shadow: inset 0px 0px 0px 1px #0074c2;
}

.loading {
  width: 100px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  display: inline-block;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: rgba(255, 255, 255, 0.1);
  }

  100% {
    background-color: rgba(255, 255, 255, 0.6);
  }
}

/************************************************ GLITCH *****************************************************************/

:root {
  --f-size: 15;
  --f-unit: 1vmin;
  --f: calc(var(--f-size) * var(--f-unit));
  --bg: #001137;
}

.glitch {
  flex: 1;
  line-height: 0.75;
  margin: auto 10px;
  color: #1af0dc;
  color: #0074c2;
  color: #fff;
  text-align: center;
  transform: scaleX(var(--scale, 1));
  animation: glitch-p 11s infinite alternate;
}
.glitch::before,
.glitch::after {
  --top: 0;
  --left: 0;
  --v-height: 30%;
  --n-tenth: calc(var(--f-size) * 0.1 * var(--top));
  --t-cut: calc(var(--n-tenth) / var(--f-size) * 100%);
  --b-cut: calc(var(--t-cut) + var(--v-height));
  content: attr(data-text);
  position: absolute;
  width: 100%;
  left: 0;
  text-align: center;
  transform: translateX(calc(var(--left) * 100%));
  filter: drop-shadow(0 0 transparent);
  text-shadow: calc(var(--left) * -3em) 0 0.02em lime,
    calc(var(--left) * -6em) 0 0.02em #ff00e1;
  background-color: var(--bg);
  clip-path: polygon(
    0% var(--t-cut),
    100% var(--t-cut),
    100% var(--b-cut),
    0% var(--b-cut)
  );
}
.glitch::before {
  animation: glitch-b 1.7s infinite alternate-reverse;
}
.glitch::after {
  animation: glitch-a 3.1s infinite alternate;
}
@keyframes glitch-p {
  17% {
    --scale: 0.87;
  }
  31% {
    --scale: 1.1;
  }
  37% {
    --scale: 1;
  }
  47% {
    --scale: 0.91;
  }
  87% {
    --scale: 1;
  }
}
@keyframes glitch-a {
  10%,
  30%,
  50%,
  70%,
  90% {
    --top: 0;
    --left: 0;
  }
  0% {
    --v-height: 15%;
  }
  20% {
    --left: 0.005;
  }
  40% {
    --left: 0.01;
    --v-height: 20%;
    --top: 3;
  }
  60% {
    --left: 0.03;
    --v-height: 25%;
    --top: 6;
  }
  80% {
    --left: 0.07;
    --v-height: 5%;
    --top: 8;
  }
  100% {
    --left: 0.083;
    --v-height: 30%;
    --top: 1;
  }
}
@keyframes glitch-b {
  10%,
  30%,
  50%,
  70%,
  90% {
    --top: 0;
    --left: 0;
  }
  0% {
    --v-height: 15%;
    --top: 10;
  }
  20% {
    --left: -0.005;
  }
  40% {
    --left: -0.01;
    --v-height: 17%;
    --top: 3;
  }
  60% {
    --left: -0.03;
    --v-height: 35%;
    --top: 6;
  }
  80% {
    --left: -0.07;
    --v-height: 5%;
    --top: 8;
  }
  100% {
    --left: -0.083;
    --v-height: 30%;
    --top: 1;
  }
}

.numberCount {
  display: inline-block;
  text-align: left;
  width: 100%;
  padding-left: calc(50% - 100px);
  box-sizing: border-box;
}

.numberCount::after {
  content: 'XEC';
  position: absolute;
  right: 70px;
}

/*********************************** MODAL *****************************************/

.question {
  cursor: pointer;
  position: relative;
}

.question span {
  padding: 0px 6px;
  font-size: 14px;
  background-color: #0074c2;
  border-radius: 100px;
  margin-left: 3px;
}
.tool-tip {
  position: absolute;
  padding: 10px;
  font-size: 14px;
  background: #1c2539;
  text-align: left;
  width: 250px;
  border: 1px solid #0074c2;
  top: -130px;
  left: 80%;
  font-weight: normal;
}

.tool-tip2 {
  position: absolute;
  padding: 10px;
  font-size: 14px;
  background: #1c2539;
  text-align: left;
  width: 250px;
  border: 1px solid #0074c2;
  top: -150px;
  left: 80%;
  font-weight: normal;
}

.modal-backdrop {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(2, 13, 41, 0.8);
  position: absolute;
  z-index: 9998;
  top: 0;
  left: 0;
}

.modal-border {
  width: 80%;
  max-width: 700px;
  z-index: 9999;
  background-color: #273498;
  background-image: linear-gradient(151deg, #273498, #0074c2 53%, #00abe7);
  padding: 2px;
  text-align: center;
  line-height: 1.9em;
  font-size: 18px;
  clip-path: polygon(100% 0, 100% 100%, 7% 100%, 0 89%, 0 0);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-inner {
  width: 100%;
  padding: 30px;
  background-color: #020c23;
  clip-path: polygon(100% 0, 100% 100%, 7% 100%, 0 89%, 0 0);
}

/************************************* COUNT DOWN *************************************/

#gimmick {
  display: block;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -12;
}

.block {
  width: 100%;
  padding: 0 25px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
}

.container {
  width: 100%;
  max-width: 1200px;
}

.countdown-ctn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 400ms ease-in-out;
  box-sizing: border-box;
  /* height: 500px; */
  position: relative;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
}

.countdown-ctn video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0;
  padding: 0;
}

.gradient-transition {
  width: 100%;
  height: 200px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgb(0, 12, 37);
  background: -moz-linear-gradient(
    0deg,
    rgba(0, 12, 37, 1) 15%,
    rgba(0, 12, 37, 0) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(0, 12, 37, 1) 15%,
    rgba(0, 12, 37, 0) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(0, 12, 37, 1) 15%,
    rgba(0, 12, 37, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000c25",endColorstr="#000c25",GradientType=1);
}

.gradient-transition2 {
  width: 100%;
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(0, 12, 37);
  background: -moz-linear-gradient(
    180deg,
    rgba(0, 12, 37, 1) 15%,
    rgba(0, 12, 37, 0) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(0, 12, 37, 1) 15%,
    rgba(0, 12, 37, 0) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(0, 12, 37, 1) 15%,
    rgba(0, 12, 37, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000c25",endColorstr="#000c25",GradientType=1);
}

.scaledown {
  transform: scale(0.6) translateY(-60px);
  margin-bottom: -40px;
  transition: all 400ms ease-in-out;
}

.countdown-content {
  width: 100%;
  text-align: center;
}

.countdown-inner-ctn h4 {
  font-size: 28px;
  margin: 0;
}

.countdown-inner-ctn p {
  max-width: unset;
  margin: 0;
  margin-bottom: 30px;
  margin-top: 10px;
}

.countdown-content a {
  text-decoration: underline;
}

.countdown-content a:hover {
  color: #0074c2;
  text-decoration: underline;
}

.countdown-inner-ctn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: auto;
  flex-direction: column;
  transition: all 400ms ease-in-out;
}

.countdownContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.countdown-inner-ctn h5 {
  margin: 0;
  font-size: 18px;
  margin-top: 20px;
  text-align: center;
}

.countdown-inner-ctn h5 span {
  font-family: 'Space Mono', monospace;
}

.countdownUnitContainer {
  display: inline-block;
  margin: 0;
  text-align: center;
}

.countdownUnit {
  font-size: 50px;
  font-weight: bold;
  line-height: 1em;
  display: inline-block;
  text-align: center;
  font-family: 'Space Mono', monospace;
  background-color: rgba(0, 0, 0, 0.51);
  border: 2px solid #0074c2;
  padding: 20px;
}

.countdownLabel {
  font-size: 16px;
  margin-top: 10px;
}

.colon {
  display: inline-block;
  font-size: 50px;
  margin: -33px 10px 0 10px;
}

.blocks-left-ctn {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.blocks-left {
  margin: 0 20px;
}

.blocks-left span {
  font-weight: 700;
  color: #0074c2;
}

@media (max-width: 960px) {
  .overlay,
  .countdown-ctn video {
    display: none;
  }
  .countdown-inner-ctn h4 {
    font-size: 20px;
  }
  .scaledown {
    transform: scale(0.6) translateY(-30px);
    margin-bottom: -30px;
    transition: all 400ms ease-in-out;
  }
  .countdown-content h4 {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .countdownUnit {
    font-size: 30px;
    padding: 10px;
  }

  .colon {
    font-size: 30px;
    margin: -20px 5px 0 5px;
  }

  .countdownLabel {
    font-size: 14px;
    margin-top: 0px;
  }
}

/*********************************** MEDIA *****************************************/

@media (max-width: 800px) {
  .header-ctn {
    height: 100px;
  }
  .glitch {
    animation: none;
  }

  .glitch::before {
    animation: none;
  }
  .glitch::after {
    animation: none;
  }

  .glitch::before,
  .glitch::after {
    display: none;
  }

  h1 {
    font-size: 35px;
  }

  .blip {
    height: 40px;
    position: absolute;
    left: 40px;
    top: 10px;
  }

  .header h5 {
    position: absolute;
    top: 55px;
    margin: auto;
    left: 0;
    right: 0;
    justify-content: center;
  }

  .price-info-ctn {
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    flex-direction: column;
    width: 100%;
    padding: 0 15px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .price-block-ctn {
    display: flex;
    margin-bottom: 5px;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .price-block {
    padding: 15px 20px;
    line-height: 1.3em;
    font-size: 16px;
    margin: 0px;
    width: 100%;
    max-width: 200px;
  }

  .priceblock-icon {
    width: 30px;
    height: unset;
    margin-bottom: -10px;
    display: none;
  }

  .bar-ctn {
    margin-top: 0;
  }

  .chart-ctn {
    padding: 0 10px;
    margin-top: 30px;
    font-size: 12px;
    margin-bottom: 100px;
  }

  .row-logo {
    height: 40px;
  }

  table {
    font-size: 12px;
  }

  td {
    padding: 15px 5px;
  }

  .topRow td {
    padding: 5px 0;
  }

  .tool-tip {
    left: 0%;
  }
}
